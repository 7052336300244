/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/components/layout.css"

export const onClientEntry = () => {
    // IntersectionObserver polyfill for gatsby-background-image
    if (typeof window.IntersectionObserver === 'undefined') {
        import('intersection-observer')
        console.log('# IntersectionObserver polyfilled')
    }
}